"use client";

import { useLayoutEffect } from "react";
import { setInitialRenderDone } from "src/lib/ClientHooks";
if (typeof window !== "undefined") {
  window.addEventListener("pageshow", event => {
    if (event.persisted) {
      console.log("This page was restored from the bfcache.");
    } else {
      console.log("This page was loaded normally.");
    }
  });
}
const InitialLayoutDetector = () => {
  useLayoutEffect(() => {
    setInitialRenderDone();
  });
  return null;
};
export default InitialLayoutDetector;
"use client";

import type React from "react";
import { type IconProps, ToastContainer, toast as baseToast } from "react-toastify";
import { IconClose } from "shared/Icons";
import "./Toasts.scss";
// @ts-ignore
import { Toast, type ToastOptions } from "react-toastify/dist/types";
import IconButton from "src/components/common/IconButton/IconButton";
import Link from "src/components/common/Link/Link";
import StatusIcon from "src/components/common/StatusIcon/StatusIcon";
export const SIGN_IN_TOAST_ID = "sign_in_fail";
const toast = (message: React.ReactNode, options: ToastOptions) => {
  if (options.toastId && baseToast.isActive(options.toastId)) {
    baseToast.update(options.toastId, {
      render: message
    });
    document.querySelector(`#${options.toastId}.toast`)?.animate([{
      transform: "scale(1)"
    }, {
      transform: "scale(1.1)"
    }, {
      transform: "scale(1)"
    }], {
      duration: 500,
      fill: "forwards",
      // spring
      easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)"
    });
  } else {
    baseToast(message, options);
  }
};
export const successToast = (message: string, options?: ToastOptions) => {
  toast(message, {
    autoClose: 2000,
    ...options,
    closeButton: false,
    type: "success",
    position: "bottom-center"
  });
};
export const hideToast = (id: string) => {
  baseToast.dismiss(id);
};
export const signInFailedToast = (message?: string, options?: ErrorToastOptions) => {
  return errorToast(message ?? "Sign in failed", {
    showHelp: true,
    toastId: SIGN_IN_TOAST_ID,
    ...options
  });
};
export type ErrorToastOptions = Omit<ToastOptions, "type"> & {
  toastId?: string;
  showHelp?: boolean;
  error?: Error;
};
export const errorToast = (message: string, options?: ErrorToastOptions) => {
  toast(<div className={"flex flex-col"}>
      {message}
      {options?.showHelp ? <>
          {" "}
          <Link variant={"hyperlink"} href={"/help"}>
            Help
          </Link>
        </> : null}
    </div>, {
    autoClose: false,
    toastId: options?.toastId || `error-${message}`,
    ...options,
    //icon: options?.icon? <ToastIcon type={"error"} theme={{}} />,
    type: "error",
    position: "bottom-center"
  });
};
const ToastIcon = (props: IconProps) => {
  const {
    type
  } = props;
  if (type === "success") {
    return <StatusIcon status={"success"} />;
  }
  if (type === "error") {
    return <StatusIcon status={"danger"} />;
  }
  if (type === "warning") {
    return <StatusIcon status={"warning"} />;
  }
  return <StatusIcon status={"info"} data-sentry-element="StatusIcon" data-sentry-component="ToastIcon" data-sentry-source-file="Toasts.tsx" />;
};
export interface CloseButtonProps {
  closeToast: (event: React.MouseEvent) => void;
}
const CloseButton = (props: CloseButtonProps) => {
  const {
    closeToast
  } = props;
  return <IconButton variant={"bare"} onClick={closeToast} label={"Close notification"} className={"Toastify__close-button"} data-sentry-element="IconButton" data-sentry-component="CloseButton" data-sentry-source-file="Toasts.tsx">
      <IconClose data-sentry-element="IconClose" data-sentry-source-file="Toasts.tsx" />
    </IconButton>;
};
const Toasts = () => {
  return <ToastContainer icon={ToastIcon} closeButton={CloseButton as any} toastClassName={"toast"} data-sentry-element="ToastContainer" data-sentry-component="Toasts" data-sentry-source-file="Toasts.tsx" />;
};
export default Toasts;
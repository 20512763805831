"use client";

import { MoonLoader } from "react-spinners";
export interface LoadingSpinnerProps {
  size?: number;
  enabled?: boolean;
}
const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const {
    size,
    enabled
  } = props;
  return <MoonLoader loading={enabled ?? true} size={size ?? 24} color={"var(--color-primary)"} data-sentry-element="MoonLoader" data-sentry-component="LoadingSpinner" data-sentry-source-file="LoadingSpinner.tsx" />;
};
export default LoadingSpinner;
import React from "react";
import { IconErrorCircle, IconInfoCircle, IconSuccessCircle, IconWarningCircle } from "shared/Icons";
import type { Status } from "src/lib/FrontendTypes";
export interface StatusIconProps {
  status: Status;
  variant?: "normal" | "foreground";
}
const StatusIcon = (props: StatusIconProps) => {
  const {
    status,
    variant,
    ...otherProps
  } = props;
  let Icon = <IconInfoCircle className={variant === "foreground" ? "text-primary-foreground" : "text-primary"} />;
  if (status === "success") {
    Icon = <IconSuccessCircle className={variant === "foreground" ? "text-success-foreground" : "text-success"} />;
  } else if (status === "danger") {
    Icon = <IconErrorCircle className={variant === "foreground" ? "text-danger-foreground" : "text-danger"} />;
  } else if (status === "warning") {
    Icon = <IconWarningCircle className={variant === "foreground" ? "text-warning-foreground" : "text-warning"} />;
  }
  return React.cloneElement(Icon, otherProps);
};
export default StatusIcon;
"use client";

import { useEffect } from "react";
import { FIRST_PARTY_COOKIES } from "shared/SharedConstants";
import { useCookies, useDebouncedCallback } from "src/lib/ClientHooks";
const PreRenderedWidthManager = () => {
  const {
    setCookie
  } = useCookies();
  const updatePreRenderedWidth = useDebouncedCallback(() => {
    setCookie(FIRST_PARTY_COOKIES.PRE_RENDERED_WIDTH.name, window.innerWidth.toString());
  }, [], 300);
  useEffect(() => {
    updatePreRenderedWidth();
    window.addEventListener("resize", updatePreRenderedWidth);
    return () => {
      window.removeEventListener("resize", updatePreRenderedWidth);
    };
  }, []);
  return null;
};
export default PreRenderedWidthManager;
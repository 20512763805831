import React from "react";
export interface VisibilityContextState {
  visible: boolean;
}
const VisibilityContext = React.createContext<VisibilityContextState>({
  visible: true
});
export const useVisibility = () => {
  return React.useContext(VisibilityContext);
};
export const VisibilityProvider = VisibilityContext.Provider;